import { Container, Button, Col, Row, Badge, Card } from "react-bootstrap";
import NewAdvButton from "./NewAdvButton";

export const Sbito = ({ children }: { children: React.ReactNode }) => {

  return (
    <>
      <Container className="bg-white px-4 rounded" style={{ maxWidth: 960 }}>
        <Row className="my-2">
          <Col className="d-flex gap-3 justify-content-end align-items-center text-muted">
            <small>Magazine</small>|<small>Suggestions for sellers</small>|
            <small>Shops and companies</small>|<small>Sbito for business</small>|
            <small>Help</small>|<small>Saved searches</small>|
            <small>Favorites</small>
          </Col>
        </Row>
        <hr className="mt-0" />
        <Col className="d-flex flex-row justify-content-between align-items-center">
          <div>
            <img className="m-2" src="/images/logo.png" alt="logo" />
          </div>
          <Col className="d-flex justify-content-end p-3 gap-3">
            <Button variant="transparent">Log in</Button>
            <Button variant="transparent">Sign in</Button>
            <NewAdvButton />
          </Col>
        </Col>
        <hr />
        <Col className="d-flex flex-row align-items-center gap-3">
          <Button variant="transparent">{"< Back to search"}</Button>
          <small className="d-flex gap-1 text-muted">
            <span>Sbito.it</span>
            {">"}
            <span>Cars</span>
            {">"}
            <span>Lazio</span>
            {">"}
            <span>Frosinone (Prov)</span>
            {">"}
            <span>Cassino</span>
          </small>
        </Col>
        <div className="d-flex gap-4 mt-4">
          <div style={{ maxWidth: 620 }}>
            <div>
              <img src="/images/jaguar.png" width="100%" alt="jaguar" />
            </div>
            <Col className="my-3 d-flex flex-column justify-content-stretch align-items-stretch gap-3">
              <h4>Main data</h4>
              <div className="d-flex">Used 03/2017 95000 km diesel</div>
              <div className="d-flex">
                Automatic
              </div>
              <div className="d-flex"></div>
              <hr />
              <h4>Description</h4>
              <p>
                MC Auto Cassino Financing on site for all ours
                Cars we offer the possibility of collecting your used car
                In exchange, we also offer the financing service
                HEADQUARTER. For each car there is a sanitization service
                And preconsegna igenization! CAR: the only owner
                !!! Fuel - Diesel 12 months warranty !!! ACCESSORIES: -
                ABS, - ESP, - ASR, - Airbag, - Air conditioning - Air
                B -ZONA conditioning - Multifunction steering wheel controls - Sensors
                Parking - Various driving methods - Cruise Control -
                Leather seats - Start and Stop - Navigator etc. Ecc.ecc Tenuta
                Very well, regularly serviced and with all the works of
                ordinary maintenance punctually performed and certifiable !!
                "Certified Km" equipped with: car review performed, stamp duty
                paid and double keys. Car never engraved !! AVAILABLE
                From SBito for any test or control also by the
                Your trusted mechanic! Our cars are purchased
                Exclusively in the best dealers in Northern Italy al
                in order to guarantee our customers superior quality
                used, but above all the certification of kilometers i
                our ads, on the other hand, are inserted with 100%real photos,
                but above all with the visible plaque, giving way to carry out
                numerous checks even before coming to view the car e
                Thus guaranteeing the maximum of transparency! To find out "what
                They say about us "Consult all the reviews." Come and visit us "
                Cassino Via Ausonia Vecchia Km1,838 "(FR) near the toll booth
                Motorway !!! For more information on our company
                And on our headquarters go to Google "Mc Auto Cassino" and you will find
                All the information you need, or contact us
                Phone without any commitment !!
                <br />
                <br />
                Marco : PHONE 348 3563353
                <br />
                Gianmarco PHONE: 3456373533 MCAUTO
                <br />
                CASSINO PHONE : 0563 1729225
              </p>
              {/* WIDGET HERE */}
              {children}
              <hr />

              <h4>Vehicle history</h4>
              <span>
                <small className="text-muted">Brand</small> Jaguar
              </span>
              <span>
                <small className="text-muted">Template</small> F-Pace (X761)
              </span>
              <span>
                <small className="text-muted">Version</small> F-Pace 2.0 D 180
                CV aut. R-Sport
              </span>
              <span>
                <small className="text-muted">Places</small> 5
              </span>
              <span>
                <small className="text-muted">Vehicle type</small> Used
              </span>
              <hr />
              <h3>Advertiser</h3>
              <Card className="p-4">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <Card style={{ backgroundColor: "#eee" }}>
                    <i className="bi bi-house px-3 py-1 fs-1 text-muted" />
                  </Card>
                  <div className="d-flex flex-column align-items-start">
                    <Badge bg="lightgray">
                      <small className="text-muted">AGENCY</small>
                    </Badge>
                    <span className="fs-4 fw-bold">MC AUTO SRLS</span>
                  </div>
                </div>
              </Card>
            </Col>
          </div>
          <div>
            <div className="d-flex flex-column w-100 gap-2 justify-content-between">
              <div className="d-flex justify-content-between">
                <Badge bg="lightgray">
                  <small className="text-muted">CAR</small>
                </Badge>
                <div className="d-flex gap-4">
                  <i className="bi bi-share" />
                  <i className="bi bi-heart" />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <small>
                  <small>10 ago alle 11:45</small> |{" "}
                  <i className="bi bi-heart" /> <b>28</b>
                </small>
                <small className="text-muted">ID: 451307388</small>
              </div>
              <span className="fs-4 fw-bold">
                JAGUAR F-Pace 2.0 D 180 CV R-Sport
              </span>
              <small>
                <i className="bi bi-geo-alt" />
                CASSINO (FR)
              </small>
              <span className="fs-2 my-4 text-shockblue fw-bold">33.50 €</span>
              <a href="/">Calculate long term rental</a>
              <a href="/">Calculate financing</a>
              <hr className="mb-0" />
            </div>
          </div>
        </div>
      </Container>
      <Container
        className="px-5 my-4 d-flex justify-content-between text-shockblue fs-bold"
        style={{ maxWidth: 960, backgroundColor: "transparent" }}
      >
        <span>
          <i className="bi bi-share mr-3" />
          &nbsp;&nbsp;Share
        </span>
        <span>
          <i className="bi bi-danger mr-3" />
          &nbsp;&nbsp;Report announcement
          <span className="text-muted ms-3">ID: 451307388</span>
        </span>
      </Container>
      <Container
        className="bg-white px-4 py-4 mb-5 rounded d-flex flex-column"
        style={{ maxWidth: 960 }}
      >
        <h5>Recommended searches</h5>
        <div className="d-flex flex-row">
          <small className="text-muted">e pace jaguar</small>
          <small className="text-muted">fsfdgs pace</small>
          <small className="text-muted">fsdfh pace km 0</small>
          <small className="text-muted">sdfhs pace jaguar</small>
          <small className="text-muted">f pace</small>
          <small className="text-muted">shdf pace km 0</small>
          <small className="text-muted">e pace jaguar</small>
          <small className="text-muted">sdfh pace</small>
          <small className="text-muted">fdhs pace km 0</small>
          <small className="text-muted">esdfh pace jaguar</small>
          <small className="text-muted">fsh pace</small>
          <small className="text-muted">fshf pace km 0</small>
          <small className="text-muted">esfhd pace jaguar</small>
          <small className="text-muted">fhsf pace</small>
          <small className="text-muted">fsh pace km 0</small>
        </div>
      </Container>
    </>
  );
};

export default Sbito;
