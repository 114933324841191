import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_KEY from "./API_KEY.json";
const createEscrowRequest = async (
  summary: string,
  amount: string,
  extURL: string,
  createdBy = "a.santona@radixgroup.org"
) => {
  const body = new URLSearchParams({
    summary,
    amount,
    extURL,
    createdBy,
  });
  const resp = await fetch(
    "https://beta.uniscrow.com/version-test/api/1.1/wf/create-escrow-and-deposit-req",
    {
      method: "POST",
      mode: "cors",
      body: body,
      headers: {
        Authorization: `Bearer ${API_KEY.key}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then((resp) => resp.json());
  return resp;
};

export let advData = {
  matriculation: "2002-12",
  type: "sell",
  category: "car",
  attachment: new File([""], "filename"),
  amount: "3999.99",
  uniscrow: false,
  description: "Listing of a car lorem ipsum dolor sit amet",
  title: "Listing title",
  isNotOk: true,
  escrow: "1661951392176x428052702006870000",
  widgetSize: "sm",
};

export const NewAdv = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(advData);
  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    advData = { ...data, isNotOk: false };
    const resp = await createEscrowRequest(
      data.title,
      data.amount,
      window.location.href,
      "a.santona@radixgroup.org"
    );
    advData = { ...advData, escrow: resp.response.id };
    navigate("/user-adv");
  };
  return (
    <Form id="advForm" onSubmit={onFormSubmit}>
      <Container className="bg-white rounded min-vh-100" style={{ width: 940 }}>
        <Row className="my-2">
          <Col className="d-flex gap-3 justify-content-end align-items-center text-muted">
            <small>Magazine</small>|<small>Suggestions for sallers</small>|
            <small>Shops and companies</small>|
            <small>Sbito for companies</small>|<small>Help</small>|
            <small>Saved searches</small>|<small>Favorites</small>
          </Col>
        </Row>
        <hr className="mt-0" />
        <Col className="d-flex flex-row justify-content-between align-items-center">
          <div>
            <img className="m-2" src="/images/logo.png" alt="logo" />
          </div>
          <Col className="d-flex justify-content-end p-3 gap-3">
            <Button variant="transparent" className="border-sbito text-sbito">
              Log out
            </Button>
          </Col>
        </Col>
        <hr className="mb-0" />
        <Row>
          <Col className="bg-dimblue p-4" style={{ maxWidth: 260 }}>
            <h2>
              <b>Create listing</b>
            </h2>
            <b className="text-sbito">Just a few things...</b>
            <p>
              The announcement will be published if he respects the rules of
              Sbito. Do not Insert the same announcement several times. Do not
              use the ad to make marketing or advertising.
            </p>
            <Button variant="transparent" className="border-sbito text-sbito">
              Rules
            </Button>
          </Col>
          <Col className="p-5 d-flex flex-column gap-3">
            <div>
              <b>Category</b>{" "}
              <Form.Select
                required
                onChange={(e) => setData({ ...data, category: e.target.value })}
              >
                <option value="car">Car</option>
                <option value="bike">Bike</option>
                <option value="other">Other</option>
              </Form.Select>
            </div>
            <div className="d-flex gap-3">
              <b>Listing type</b>
              <Form.Check
                required
                onChange={(e) => setData({ ...data, type: e.target.value })}
                name="type"
                value="sell"
                type="radio"
                label="Sell"
                defaultChecked
              />
              <Form.Check
                required
                onChange={(e) => setData({ ...data, type: e.target.value })}
                name="type"
                value="buy"
                type="radio"
                label="Buy"
              />
            </div>
            <div>
              <b>Month/year of matriculation</b>
              <Form.Control
                required
                onChange={(e) =>
                  setData({ ...data, matriculation: e.target.value })
                }
                defaultValue={data.matriculation}
                type="month"
                style={{ userSelect: "none" }}
              />
            </div>
            <div>
              <b>Listing title</b>
              <Form.Control
                required
                onChange={(e) => setData({ ...data, title: e.target.value })}
                defaultValue={data.title}
              />
            </div>
            <div>
              <b>Listing description</b>
              <div>
                <textarea
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  className="w-100"
                  form="advForm"
                  required
                  defaultValue={data.description}
                />
              </div>
            </div>
            <div>
              <b>Picture</b>
              <Form.Control
                type="file"
                accept="image/png, image/gif, image/jpeg"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!e.target.files) {
                    return;
                  }
                  setData({ ...data, attachment: e.target.files[0] });
                }}
              />
            </div>
            <div>
              <b>Price</b>
              <Form.Control
                required
                onChange={(e) => setData({ ...data, amount: e.target.value })}
                type="number"
                step={0.01}
                defaultValue={data.amount}
              />
            </div>
            <div className="d-flex flex-column gap-3">
              {" "}
              Do you want to create an escrow for this listing? <br />
              <Form.Check
                required
                value={"uniscrow"}
                onChange={(e) =>
                  setData({ ...data, uniscrow: e.target.checked })
                }
                label="Set Uniscrow as escrow provider"
              />
              {data.uniscrow && (
                <div>
                  <div>
                    <b>Widget size</b>
                    <Form.Select
                      required
                      onChange={(e) =>
                        setData({ ...data, widgetSize: e.target.value })
                      }
                    >
                      <option value="sm">Small</option>
                      <option value="md">Medium</option>
                      <option value="lg">Large</option>
                    </Form.Select>
                  </div>
                  <br />
                  <i className="text-muted">Preview:</i>
                  <br />
                  <div style={{ pointerEvents: "none" }}>
                    <uniscrow-widget
                      escrow={data.escrow}
                      amount={data.amount}
                      vendor={window.location.origin}
                      size={data.widgetSize}
                    ></uniscrow-widget>
                  </div>
                </div>
              )}
            </div>
            <Button
              type="submit"
              className="text-white bg-sbito fs-5 align-self-end py-2 px-5"
            >
              Publish
            </Button>
          </Col>
        </Row>
        <hr className="my-0" />
      </Container>
      <div
        style={{ height: 200 }}
        className="bg-muted text-muted d-flex justify-content-center align-items-center"
      >
        <img
          style={{ filter: "grayscale(100%)", opacity: 0.3 }}
          className="m-2"
          src="/images/logo.png"
          alt="logo"
        />
      </div>
    </Form>
  );
};

export default NewAdv;
