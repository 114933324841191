import DemoAdv from "./components/DemoAdv";
import NewAdv from "./components/NewAdv";
import UserAdv from "./components/UserAdv";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles/App.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "uniscrow-widget": any;
    }
  }
}
export const App = () => {
  return (
    <Routes>
      <Route index element={<Navigate replace to={`/demo-adv`} />} />
      <Route
        path={`demo-adv`}
        element={
          <DemoAdv>
            <uniscrow-widget
              escrow="1661951392176x428052702006870000"
              amount="33.50"
              vendor=""
              size="md"
            ></uniscrow-widget>
          </DemoAdv>
        }
      />
      <Route path={`new-adv`} element={<NewAdv />} />
      <Route path={`user-adv`} element={<UserAdv />} />
    </Routes>
  );
};

export default App;
