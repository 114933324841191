import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "react-bootstrap";

export const NewAdvButton = (props:ButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate("/new-adv")} {...props}>
      <i className="bi bi-plus-square" /> New listing
    </Button>
  );
};
export default NewAdvButton;
